import React, { useEffect } from "react";
import PropTypes from "prop-types";
import usePrevious from "components/usePrevious";
import { useInventoryType } from "views/InventoryView/useInventoryType";

const AssetsStateContext = React.createContext();
const AssetsStateDispatch = React.createContext();

const damageThemeConfig = [
  {
    dataProp: "damageType",
    colorProp: "damageTypeColor",
  },
];

function DamageProvider({ children, org, seasonId, editId }) {
  const prevSeasonId = usePrevious(seasonId);
  const {
    fetchList: fetchDamage,
    fetchMapThemes: fetchDamageMapThemes,
    fetchGeodata: fetchDamageGeodata,
    saveItem,
    saveItemState,
    deleteItemState,
    deleteItem,
    resetDeleteItem,
    resetSaveItem,
    list,
    listState,
    geodata,
    geodataState,
    themeData,
    themeRollup,
  } = useInventoryType({
    org,
    seasonId,
    type: "aois",
    subType: "damage",
    themeConfig: damageThemeConfig,
  });

  const currentEditFtr = editId
    ? geodata?.features?.find((f) => f.id === editId)
    : null;

  //   TODO: add option to bust cache
  useEffect(() => {
    async function getData() {
      const fTheme = await fetchDamageMapThemes();
      const fList = await fetchDamage();
      fetchDamageGeodata(fTheme?.data, fList?.data);
    }
    if (seasonId !== prevSeasonId) {
      if (seasonId) {
        getData();
      }
    }
  }, [
    org,
    seasonId,
    prevSeasonId,
    fetchDamage,
    fetchDamageMapThemes,
    fetchDamageGeodata,
  ]);

  return (
    <AssetsStateContext.Provider
      value={{
        damageList: list,
        damageGeodata: geodata,
        damageThemeRollup: themeRollup,
        damageThemeData: themeData,
        saveDamageState: saveItemState,
        deleteDamageState: deleteItemState,
        currentEditFtr,
        isFetchingData: listState.isLoading || geodataState.isLoading,
      }}
    >
      <AssetsStateDispatch.Provider
        value={{
          saveDamage: saveItem,
          deleteDamage: deleteItem,
          resetSave: resetSaveItem,
          resetDelete: resetDeleteItem,
        }}
      >
        {children}
      </AssetsStateDispatch.Provider>
    </AssetsStateContext.Provider>
  );
}
DamageProvider.defaultProps = {
  seasonId: null,
  editId: null,
};

DamageProvider.propTypes = {
  children: PropTypes.node.isRequired,
  org: PropTypes.string.isRequired,
  seasonId: PropTypes.string,
  editId: PropTypes.string,
};

function useDamageState() {
  const context = React.useContext(AssetsStateContext);
  if (context === undefined) {
    throw new Error("useDamageState must be used within a DamageProvider");
  }
  return context;
}

function useDamageDispatch() {
  const context = React.useContext(AssetsStateDispatch);
  if (context === undefined) {
    throw new Error("useDamageDispatch must be used within a DamageProvider");
  }
  return context;
}

export { DamageProvider, useDamageState, useDamageDispatch };
