import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fitBounds } from "./Map/utils";
import DataTable from "./DataTable";
import usePrevious from "./usePrevious";

// function to check if row element is already visible
// in the table to prevent unnecessary scrolling
function isInView(el) {
  try {
    const rect = el?.getBoundingClientRect();
    // we cannot check if visible in viewport/DOM since it
    // could be in the DOM but out of sight due to scrolling
    // so, we are getting the table container div since it has scroll hidden
    // el=TR parentNode1=TBODY  parentNode2=TABLE parentNode3=DIV
    const rect2 = el?.parentNode?.parentNode?.parentNode?.getBoundingClientRect();
    return (
      rect.top >= rect2.top &&
      // rect.left >= rect2.left &&
      rect.bottom <= rect2.bottom
      // rect.right <= rect2.right
    );
  } catch (e) {
    console.error("Failed checking if table row visible.", e);
    return false;
  }
}
export function GeoDataTable({
  map = null,
  ftrsClicked = null,
  data = [],
  featureCollection = null,
  highlightByIds = () => {},
  onRowClick = null,
  onSelectionChange = null,
  onRowMouseEnter = null,
  onRowMouseLeave = null,
  highlightOnMouseOver = null,
  highlightOnClick = null,
  ...rest
}) {
  const [selectedRow, setSelectedRow] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const prevFc = usePrevious(featureCollection);
  const prevFtrsClicked = usePrevious(ftrsClicked);

  // TODO: What was the reason for this effect?
  useEffect(() => {
    if (prevFc && prevFc !== featureCollection) {
      // console.log('geo update');
      highlightByIds(
        selectedRows.reduce((arr, r) => {
          if (r?.original?.id) {
            arr.push(r.original.id);
          }
          return arr;
        }, [])
      );
    }
  }, [highlightByIds, featureCollection, selectedRows, prevFc]);

  useEffect(() => {
    if (ftrsClicked && ftrsClicked !== prevFtrsClicked) {
      const clickedAssets =
        ftrsClicked && data
          ? data.filter((ca) =>
              ftrsClicked.find((f) => f.properties.id === ca.id)
            )
          : null;
      const sri = clickedAssets?.reduce((curr, val) => {
        const idx = data.indexOf(val);
        if (idx > -1) {
          curr[idx] = true;
        }
        return curr;
      }, {});
      setSelectedRowIds(sri);
    }
  }, [data, ftrsClicked, prevFtrsClicked]);

  useEffect(() => {
    // check for row ref (comes from DataTable library component)
    // and make sure that the table row is visible
    if (
      selectedRows.length &&
      selectedRows[0] &&
      selectedRows[0].ref &&
      selectedRows[0].ref.current
    ) {
      if (!isInView(selectedRows[0].ref.current)) {
        selectedRows[0].ref.current.scrollIntoView();
      }
    }
  }, [selectedRows]);

  return (
    <DataTable
      data={data}
      selectedRowIds={selectedRowIds}
      isSelectable={featureCollection ? "single" : false}
      onRowClick={
        highlightOnClick
          ? (e, row) => {
              if (!row.isGrouped) {
                const feature = featureCollection?.features?.find(
                  (s) => s.id === row.original.id
                );
                if (feature) {
                  if (feature === selectedRow) {
                    highlightByIds([]);
                    setSelectedRow(null);
                  } else {
                    fitBounds({
                      map,
                      geojson: feature,
                      options: { animate: false },
                    });
                    highlightByIds([feature.id]);
                    setSelectedRow(feature);
                  }
                } else {
                  setSelectedRow(null);
                  highlightByIds([]);
                }
              }
              if (onRowClick) {
                onRowClick(e, row);
              }
            }
          : onRowClick
      }
      onSelectionChange={(rows) => {
        setSelectedRows(rows);
        if (onSelectionChange) {
          onSelectionChange(rows);
        }
        highlightByIds(
          rows?.reduce((arr, r) => {
            if (r?.original?.id) {
              arr.push(r.original.id);
            }
            return arr;
          }, [])
        );
      }}
      onRowMouseEnter={
        highlightOnMouseOver
          ? (row) => {
              if (onRowMouseEnter) {
                onRowMouseEnter(row);
              }
              const id = row?.original?.id;
              if (selectedRows.length || (!id && id !== 0)) {
                return;
              }
              highlightByIds([id || null]);
            }
          : onRowMouseEnter
      }
      onRowMouseLeave={
        highlightOnMouseOver
          ? (...args) => {
              if (onRowMouseLeave) {
                onRowMouseLeave(...args);
              }
              highlightByIds(selectedRows.map((r) => r?.original?.id || null));
            }
          : onRowMouseLeave
      }
      {...rest}
    />
  );
}
GeoDataTable.defaultProps = {
  data: [],
  highlightOnClick: true,
  highlightOnMouseOver: true,
  featureCollection: null,
  ftrsClicked: null,
  onRowClick: null,
  onSelectionChange: null,
  onRowMouseEnter: null,
  onRowMouseLeave: null,
  map: null,
};

GeoDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  map: PropTypes.shape({}),
  featureCollection: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  ftrsClicked: PropTypes.arrayOf(PropTypes.shape({})),
  highlightOnClick: PropTypes.bool,
  highlightOnMouseOver: PropTypes.bool,
  highlightByIds: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onRowMouseEnter: PropTypes.func,
  onRowMouseLeave: PropTypes.func,
};
