import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AllGeoJSON, featureCollection } from "@turf/helpers";
import { useOrgState } from "providers/OrgProvider";
import {
  useInventoryState,
  useInventoryDispatch,
} from "providers/InventoryProvider";
import {
  useFieldLegendLayer,
  useMapboxDraw,
  useLegendControl,
} from "components/Map";
import MapView from "../../BaseMapView";
import {
  AnyPaint,
  CircleLayer,
  Expression,
  GeoJSONSource,
  Layout,
  LineLayer,
  Map,
  MapSourceDataEvent,
  MapboxEvent,
  PointLike,
  SymbolLayer,
  SymbolPaint,
} from "mapbox-gl";
import { useTheme } from "@mui/material/styles";
import {
  Feature,
  FeatureCollection,
  LineString,
  Point,
  Polygon,
} from "geojson";
import { IMapboxDrawOpts, IUrlParams } from "types";
import usePrevious from "components/usePrevious";
import {
  LegendSymbol,
  fitBounds,
  flatten,
  getPointOnFeature,
} from "lib/MapboxMap";
import { combineFc } from "components/Map";
import LayersControl from "./RasterLayersControl/LayersControlButton";
import LayersDrawer from "./LayersDrawer";
import {
  RasterTileLayer,
  useRasterLayers,
} from "./RasterLayersControl/useRasterLayers";
import { useAppState } from "providers/AppProvider";
import { useEnterpriseLayers } from "./EnterpriseLayers/useEnterpriseLayers";
import { RasterLegendItem } from "./RasterLayersControl/useRasterLegends";
import { useParams } from "react-router-dom";
// TODO: configure max label zoom
const MIN_LABEL_ZOOM = 11;
const noop = () => {};
const emptyFc = featureCollection([]) as FeatureCollection;

function getPaint(d: {
  geomType: string;
  color?: string | Expression;
  fillAsLine?: boolean;
}): AnyPaint {
  const { geomType, color } = d;
  const finalColor = color ?? "#fefefe";
  switch (geomType) {
    case "point":
    case "multipoint": {
      return {
        "circle-radius": 3,
        "circle-stroke-color": finalColor,
        "circle-stroke-width": 2,
        "circle-color": finalColor,
        "circle-opacity": 0.3,
      };
    }
    case "linestring":
    case "multilinestring": {
      return {
        "line-width": 3,
        "line-color": finalColor,
      };
    }
    case "polygon":
    case "multipolygon": {
      if (d.fillAsLine) {
        return {
          "line-width": 3,
          "line-color": finalColor,
        };
      }
      return {
        "fill-opacity": 0,
        "fill-color": finalColor,
      };
    }
    default: {
      console.error("Failed getting paint props, invalid geometry type.");
    }
  }
}

export default function InventoryMapView({
  mapRef: mR,
  inventoryFeatures,
  isLoading,
  draw,
  onDrawChange,
  outsideDrawData,
  fields,
  drawOptions,
  hideFields,
  showFieldLabels,
  editFeature,
  labelProp,
}: {
  mapRef: (_m: Map) => void;
  inventoryFeatures: FeatureCollection;
  isLoading: boolean;
  draw: boolean;
  onDrawChange: (_fc: FeatureCollection) => void;
  outsideDrawData: Feature;
  fields: FeatureCollection;
  drawOptions: IMapboxDrawOpts;
  hideFields: boolean;
  showFieldLabels: boolean;
  editFeature: Feature;
  labelProp: string;
}) {
  const { t } = useTranslation();
  const { type } = useParams<IUrlParams>();
  const [showLayersDrawer, setShowLayersDrawer] = useState<boolean>();
  const labelLyrPaintProp: SymbolPaint = useMemo(
    () => ({
      "text-halo-color": "hsl(0, 0%, 17%)",
      "text-color": "hsl(0, 0%, 100%)",
      "text-halo-width": [
        "interpolate",
        ["exponential", 1],
        ["zoom"],
        14,
        1.25,
        15,
        1.5,
      ],
    }),
    []
  );
  const labelLyrLayoutProp = useMemo(
    () => ({
      "text-font": ["literal", ["Roboto Condensed Bold"]],
      "text-field": ["coalesce", ["get", labelProp] as Expression, ""],
      "text-justify": "auto",
    }),
    [labelProp]
  );

  const { orgData, configQuery, season } = useOrgState();
  const { tenant } = useAppState();
  const activeRasterLayers = useRasterLayers(tenant, orgData?.slug);
  const enterpriseLayers = useEnterpriseLayers();
  const [rastersLoading, setRastersLoading] = useState(false);
  const fieldLegendLayer = useFieldLegendLayer();
  const { legendData } = useInventoryState();
  const { setFeaturesClicked } = useInventoryDispatch();
  const mapRef = React.useRef<Map>();
  // const [mapLoaded, setMapLoaded] = useState<boolean>();

  function updatePolyLabels() {
    if (mapRef.current && mapRef.current.getZoom() >= MIN_LABEL_ZOOM) {
      const src = mapRef.current.getSource("label-features") as GeoJSONSource;
      if (src) {
        src.setData(emptyFc);
      }
      const polyLyr = mapRef.current.getLayer("inventoryFtrsPoly");
      if (polyLyr) {
        const selectedFeatures = mapRef.current.queryRenderedFeatures(null, {
          layers: ["inventoryFtrsPoly"],
        });
        const result = featureCollection([]) as FeatureCollection<
          Point | LineString | Polygon
        >;

        if (selectedFeatures?.length) {
          // get unique IDs
          const ids = selectedFeatures.reduce((arr, item) => {
            if (!arr.includes(item.properties.id)) {
              arr.push(item.properties.id);
            }
            return arr;
          }, []);
          ids.forEach((id) => {
            // since mapbox uses vector tile layers that slice up the geojson
            // dependent on view, we need to get all the feature "pieces" and merge them
            // together before getting the centroid for our labels
            const ftrsById = selectedFeatures.filter(
              (f) => f.properties.id === id
            );
            const fc = featureCollection(ftrsById) as FeatureCollection<
              Point | LineString | Polygon
            >;

            const com = combineFc(fc);
            const center = getPointOnFeature(com);
            // one point feature per polygon feature to use for labels
            result.features.push({
              type: "Feature",
              properties: ftrsById[0].properties,
              geometry: center.geometry,
            });
          });

          src.setData(result);
        }
      }
    }
  }

  const theme = useTheme();

  const rasterLegendItems = useMemo(() => {
    const uniqRasterTypes = [
      ...new Set(
        activeRasterLayers?.filter((ar) => ar.visible)?.map((i) => i.rasterType)
      ),
    ];
    return uniqRasterTypes?.length
      ? uniqRasterTypes?.map((u) => {
          const ar = activeRasterLayers.find((ar) => ar.rasterType === u);
          return {
            id: `${ar.url}-lyr`,
            isStatic: true,
            title: ar.rasterType.toUpperCase(),
            data: ar.legendInfo?.data?.map((i) => ({
              name: i.label,
              Symbol: function Symbol() {
                return (
                  <LegendSymbol
                    geometryType="Polygon"
                    backgroundColor={i.color}
                  />
                );
              },
            })),
          };
        })
      : [];
  }, [activeRasterLayers]);

  const legendItems = [
    fieldLegendLayer,
    {
      // handle point, line, and poly layers
      ids: ["inventoryFtrsPnt", "inventoryFtrsLine", "inventoryFtrsPoly"],
      title: legendData?.title,
      dataProp: legendData?.dataProp,
      // color: getThemeColor,
      Symbol: function Symbol(props: {
        geometryType: string;
        data: Record<string, unknown>;
      }) {
        return (
          <LegendSymbol
            geometryType={props.geometryType || legendData?.geometryType}
            // add background color for point symbols
            backgroundColor={
              props.geometryType?.includes("Point")
                ? `${props.data?.[legendData?.colorProp]}4D`
                : null
            }
            borderColor={`${props.data?.[legendData?.colorProp] || "#000"}`}
            borderWidth={2}
          />
        );
      },
    },
    ...[...(rasterLegendItems?.length ? rasterLegendItems : [])],
  ];
  const { legendCtrl } = useLegendControl({
    theme,
    location: "bottom-right",
    layers: legendItems,
  });

  const prevEditFtr = usePrevious(editFeature);
  const prevInventoryFtrs = usePrevious(inventoryFeatures);
  const prevColorProp = usePrevious(legendData?.colorProp);
  const prevDraw = usePrevious(draw);
  const prevDrawOpts = usePrevious(drawOptions);

  function flattenEditFeature(ftr: Feature) {
    try {
      const toFc = featureCollection([ftr]);
      const flattenedFc =
        toFc && toFc.features.length
          ? flatten(toFc.features[0] as AllGeoJSON)
          : toFc;
      // Individual polygons need unique IDs, so adding
      // index to ID for exploded/flattened polygons
      if (flattenedFc) {
        flattenedFc.features.forEach((f, idx) => {
          f.id = toFc ? `${toFc.features[0].id}-${idx}` : null;
        });
      }
      return flattenedFc;
    } catch (e) {
      console.error("failed flattening the feature", e);
    }
  }

  const {
    setMap,
    addDrawControl,
    removeDrawControl,
    setFeatures,
    mode,
    setMode,
    hasSelection,
  } = useMapboxDraw({
    areaUnits: configQuery.data?.uoms.area,
    distanceUnits: configQuery.data?.uoms.distance,
    defaultFeatures: editFeature ? flattenEditFeature(editFeature) : null,
    controls: drawOptions?.controls || {
      trash: true,
      polygon: true,
    },
    defaultMode: drawOptions?.defaultMode || "simple_select",
    onDrawChange(fc: FeatureCollection<Point | LineString | Polygon>) {
      // handling multi-geometry behind
      // the scenes by merging when they go out
      // the door and flattening when data comes in
      if (fc.features.length) {
        // let fcWithId = fc;
        if (fc.features.length > 1) {
          const fcWithId = combineFc(fc) as FeatureCollection;
          onDrawChange(fcWithId);
        } else {
          onDrawChange(fc);
        }
      } else {
        onDrawChange(null);
      }
    },
  });

  const addInventoryLayers = useCallback(
    (ftrs: FeatureCollection, themeProp: string) => {
      if (mapRef.current) {
        const geomTypes = ftrs?.features.reduce((arr, item) => {
          if (item.geometry && !arr.includes(item.geometry.type)) {
            arr.push(item.geometry.type);
          }
          return arr;
        }, []);
        const color = themeProp
          ? (["coalesce", ["get", themeProp], "#000"] as Expression)
          : "#000";
        // Cleanup previous layers
        const activeLayerLine = mapRef.current.getLayer("inventoryActiveLine");
        if (activeLayerLine) {
          mapRef.current.removeLayer("inventoryActiveLine");
        }
        const activeLayerPnt = mapRef.current.getLayer("inventoryActivePnt");
        if (activeLayerPnt) {
          mapRef.current.removeLayer("inventoryActivePnt");
        }
        const symbolLyr = mapRef.current.getLayer("inventoryFtrsSymbols");
        const symbolLyr2 = mapRef.current.getLayer("inventoryFtrsSymbols2");
        const symbolLyr3 = mapRef.current.getLayer("inventoryFtrsSymbols3");
        if (symbolLyr) {
          mapRef.current.removeLayer("inventoryFtrsSymbols");
        }
        if (symbolLyr2) {
          mapRef.current.removeLayer("inventoryFtrsSymbols2");
        }
        if (symbolLyr3) {
          mapRef.current.removeLayer("inventoryFtrsSymbols3");
        }
        const pntLyr = mapRef.current.getLayer("inventoryFtrsPnt");
        if (pntLyr) {
          mapRef.current.removeLayer("inventoryFtrsPnt");
        }
        const lineLyr = mapRef.current.getLayer("inventoryFtrsLine");
        if (lineLyr) {
          mapRef.current.removeLayer("inventoryFtrsLine");
        }
        const polyLyr = mapRef.current.getLayer("inventoryFtrsPoly");
        if (polyLyr) {
          mapRef.current.removeLayer("inventoryFtrsPoly");
          mapRef.current.removeLayer("inventoryFtrsPoly-fill");
        }
        if (geomTypes) {
          geomTypes.forEach((gt) => {
            switch (gt) {
              case "Point":
              case "MultiPoint": {
                // ensure we only add once
                if (!mapRef.current.getLayer("inventoryFtrsPnt")) {
                  mapRef.current.addLayer({
                    id: `inventoryFtrsPnt`,
                    type: "circle",
                    source: "inventory-features",
                    paint: {
                      "circle-radius": 3,
                      "circle-stroke-color": color,
                      "circle-stroke-width": 2,
                      "circle-color": color,
                      "circle-opacity": 0.3,
                    },
                    filter: [
                      "match",
                      ["geometry-type"],
                      ["Point", "MultiPoint"],
                      true,
                      false,
                    ],
                  });
                }
                break;
              }
              case "LineString":
              case "MultiLineString": {
                // ensure we only add once
                if (!mapRef.current.getLayer("inventoryFtrsLine")) {
                  mapRef.current.addLayer({
                    id: `inventoryFtrsLine`,
                    type: "line",
                    source: "inventory-features",
                    paint: {
                      "line-width": 3,
                      "line-color": color,
                    },
                    filter: [
                      "match",
                      ["geometry-type"],
                      ["LineString", "MultiLineString"],
                      true,
                      false,
                    ],
                  });
                }

                break;
              }
              case "Polygon":
              case "MultiPolygon": {
                // ensure we only add once
                if (!mapRef.current.getLayer("inventoryFtrsPoly")) {
                  mapRef.current.addLayer({
                    id: `inventoryFtrsPoly`,
                    type: "line",
                    source: "inventory-features",
                    paint: {
                      "line-width": 2,
                      "line-color": color,
                    },
                    filter: [
                      "match",
                      ["geometry-type"],
                      ["Polygon", "MultiPolygon"],
                      true,
                      false,
                    ],
                  });
                  mapRef.current.addLayer({
                    id: `inventoryFtrsPoly-fill`,
                    type: "fill",
                    source: "inventory-features",
                    paint: {
                      "fill-opacity":
                        type === "operations"
                          ? 0.2
                          : type === "damage"
                          ? 0.5
                          : 0,
                      "fill-color": color,
                    },
                    filter: [
                      "match",
                      ["geometry-type"],
                      ["Polygon", "MultiPolygon"],
                      true,
                      false,
                    ],
                  });
                }
                break;
              }
              default: {
                break;
              }
            }
          });
        }
        // label layers
        mapRef.current.addLayer({
          id: "inventoryFtrsSymbols",
          type: "symbol",
          source: "inventory-features",
          paint: labelLyrPaintProp,
          filter: [
            "match",
            ["geometry-type"],
            ["Point", "MultiPoint"],
            true,
            false,
          ],
          layout: {
            ...labelLyrLayoutProp,
            ...{ "text-offset": [0, 1] },
          } as Layout,
          minzoom: MIN_LABEL_ZOOM,
        });
        mapRef.current.addLayer({
          id: "inventoryFtrsSymbols2",
          type: "symbol",
          source: "inventory-features",
          paint: labelLyrPaintProp,
          layout: {
            ...labelLyrLayoutProp,
            ...{ "symbol-placement": "line", "text-offset": [0, 1] },
          } as Layout,
          minzoom: MIN_LABEL_ZOOM,
          filter: [
            "match",
            ["geometry-type"],
            ["LineString", "MultiLineString"],
            true,
            false,
          ],
        });
        mapRef.current.addLayer({
          id: "inventoryFtrsSymbols3",
          type: "symbol",
          source: "label-features",
          paint: labelLyrPaintProp,
          layout: labelLyrLayoutProp as Layout,
          minzoom: MIN_LABEL_ZOOM,
        });

        // active/highlight layers - defaults to filter all (not visible)
        mapRef.current.addLayer({
          id: "inventoryActiveLine",
          type: "line",
          source: "inventory-features-highlight",
          filter: ["==", "1", "2"],
          paint: {
            "line-width": 3,
            "line-color": "cyan",
          },
        });
        mapRef.current.addLayer({
          id: "inventoryActivePnt",
          type: "circle",
          source: "inventory-features-highlight",
          filter: ["==", "1", "2"],
          paint: {
            "circle-radius": 3,
            "circle-stroke-color": "cyan",
            "circle-stroke-width": 2,
            "circle-color": ["coalesce", ["get", color], "#00ffff"],
            "circle-opacity": 0.3,
          },
        });
      }
    },
    [labelLyrLayoutProp, labelLyrPaintProp, type]
  );
  const setLayerProperties = useCallback(() => {
    if (mapRef.current) {
      const colorProp = legendData?.colorProp;
      const pntLyr = mapRef.current.getLayer("inventoryFtrsPnt");
      const lineLyr = mapRef.current.getLayer("inventoryFtrsLine");
      const polyLyr = mapRef.current.getLayer("inventoryFtrsPoly");
      const colorExpression = colorProp
        ? ["coalesce", ["get", colorProp], "#000"]
        : "#000";
      if (pntLyr) {
        mapRef.current.setPaintProperty(
          "inventoryFtrsPnt",
          "circle-color",
          colorExpression
        );
        mapRef.current.setPaintProperty(
          "inventoryFtrsPnt",
          "circle-stroke-color",
          colorExpression
        );
      }
      if (lineLyr) {
        mapRef.current.setPaintProperty(
          "inventoryFtrsLine",
          "line-color",
          colorExpression
        );
      }
      if (polyLyr) {
        mapRef.current.setPaintProperty(
          "inventoryFtrsPoly-fill",
          "fill-color",
          colorExpression
        );
        mapRef.current.setPaintProperty(
          "inventoryFtrsPoly",
          "line-color",
          colorExpression
        );
      }
    }
  }, [legendData]);

  const addInventorySource = useCallback(
    (ftrs) => {
      if (mapRef.current) {
        const allSrc = mapRef.current.getSource(
          "inventory-features"
        ) as GeoJSONSource;
        const activeSrc = mapRef.current.getSource(
          "inventory-features-highlight"
        ) as GeoJSONSource;
        if (!allSrc) {
          mapRef.current.addSource("inventory-features", {
            type: "geojson",
            data: ftrs,
          });
          mapRef.current.addSource("inventory-features-highlight", {
            type: "geojson",
            data: ftrs,
          });
        } else {
          allSrc.setData(ftrs || emptyFc);
          activeSrc.setData(ftrs || emptyFc);
        }

        const labelSrc = mapRef.current.getSource(
          "label-features"
        ) as GeoJSONSource;
        if (!labelSrc) {
          mapRef.current.addSource("label-features", {
            type: "geojson",
            data: emptyFc,
          });
        } else {
          labelSrc.setData(emptyFc);
        }

        addInventoryLayers(ftrs, legendData?.colorProp);
      }
    },
    [legendData?.colorProp, addInventoryLayers]
  );

  const addFields = useCallback(
    (ftrs) => {
      if (ftrs && mapRef.current) {
        const fieldsSrc = mapRef.current.getSource("fields") as GeoJSONSource;
        if (fieldsSrc) {
          fieldsSrc.setData(ftrs);
          return;
        }
        const labelLyrOpts = {
          id: "fieldsSymbols",
          type: "symbol",
          source: "fields",
          paint: labelLyrPaintProp,
          layout: {
            "text-font": ["literal", ["Roboto Condensed Bold"]],
            "text-field": ["coalesce", ["get", "name"], ""],
            "text-justify": "auto",
          },
          minzoom: MIN_LABEL_ZOOM,
        } as SymbolLayer;
        const lyrOpts = {
          id: "fields-lyr",
          type: "line",
          source: "fields",
          paint: {
            "line-width": 3,
            "line-color": "#fff",
          },
        } as LineLayer;
        if (hideFields) {
          lyrOpts.filter = ["==", "1", "2"];
        }
        if (!showFieldLabels) {
          labelLyrOpts.filter = ["==", "1", "2"];
        }
        mapRef.current.addSource("fields", {
          type: "geojson",
          data: ftrs,
        });
        mapRef.current.addLayer(lyrOpts);
        mapRef.current.addLayer(labelLyrOpts);
      }
    },
    [hideFields, labelLyrPaintProp, showFieldLabels]
  );

  const handleStyleLoad = useCallback(() => {
    if (fields) {
      addFields(fields);
    }
    if (inventoryFeatures) {
      addInventorySource(inventoryFeatures);
    }
  }, [addFields, addInventorySource, fields, inventoryFeatures]);

  function getHelpText() {
    switch (mode) {
      case "draw_polygon":
      case "draw_rectangle":
      case "draw_point":
      case "draw_line_string":
        return t("map.touchToStartDraw");
      case "simple_select":
        if (hasSelection) {
          return t("map.selectOrDeleteItem");
        }
        return t("map.selectFeatureToEdit");
      case "direct_select":
        return t("map.editBoundaryInfo");
      default:
        return "";
    }
  }

  const addRasterLayers = useCallback(() => {
    const layersSplit = activeRasterLayers?.reduce<{
      visible: RasterTileLayer[];
      notVisible: RasterTileLayer[];
    }>(
      (prev, curr) => {
        if (curr.visible) {
          prev.visible.push(curr);
        } else {
          prev.notVisible.push(curr);
        }
        return prev;
      },
      { visible: [], notVisible: [] }
    );
    const legendData: { title: string; data: RasterLegendItem[] }[] = [];
    layersSplit?.visible.forEach((l, idx) => {
      if (mapRef.current) {
        legendData.push(l.legendInfo);
        const beforeLayer = layersSplit.visible[idx - 1];
        const hasBeforeLayer = beforeLayer
          ? mapRef.current.getLayer(`${beforeLayer.url}-lyr`)
          : false;

        if (mapRef.current.getLayer(`${l.url}-lyr`)) {
          // already there, move it if needed
          if (beforeLayer && hasBeforeLayer) {
            mapRef.current.moveLayer(`${l.url}-lyr`, `${beforeLayer.url}-lyr`);
          }
          return;
        }
        // add
        if (!mapRef.current.getSource(`${l.url}-src`)) {
          mapRef.current.addSource(`${l.url}-src`, {
            type: "raster",
            tiles: [l.url],
            maxzoom: 24,
            bounds: l.bbox,
          });
        }
        if (!mapRef.current.getLayer(`${l.url}-lyr`)) {
          mapRef.current.addLayer(
            {
              id: `${l.url}-lyr`,
              source: `${l.url}-src`,
              type: "raster",
              minzoom: 3,
            },
            // specify layer ID to insert this before
            hasBeforeLayer ? `${beforeLayer.url}-lyr` : "z-index-layer"
          );
        }
      }
    });
    layersSplit?.notVisible.forEach((l) => {
      if (mapRef.current && mapRef.current.getLayer(`${l.url}-lyr`)) {
        mapRef.current.removeLayer(`${l.url}-lyr`);
        mapRef.current.removeSource(`${l.url}-src`);
      }
    });
  }, [activeRasterLayers]);

  // update fields lyr feature visibility on prop change
  useEffect(() => {
    if (mapRef.current) {
      const lyr = mapRef.current.getLayer("fields-lyr");
      if (hideFields) {
        if (lyr) {
          mapRef.current.setFilter("fields-lyr", ["==", "1", "2"]);
        }
      } else if (lyr) {
        mapRef.current.setFilter("fields-lyr", null);
      }
    }
  }, [hideFields]);

  // update field labels visibility on prop change
  useEffect(() => {
    if (mapRef.current) {
      const lyr = mapRef.current.getLayer("fieldsSymbols");
      if (!showFieldLabels) {
        if (lyr) {
          mapRef.current.setFilter("fieldsSymbols", ["==", "1", "2"]);
        }
      } else if (lyr) {
        mapRef.current.setFilter("fieldsSymbols", null);
      }
    }
  }, [showFieldLabels]);

  // SET EDIT DATA WHEN EDIT FEATURE CHANGES
  useEffect(() => {
    // ignore if we have already set the edit feature
    if (editFeature && editFeature !== prevEditFtr) {
      const geojson = editFeature
        ? (flattenEditFeature(editFeature) as FeatureCollection<
            Point | LineString | Polygon
          >)
        : null;
      if (geojson) {
        setFeatures(geojson);
      }
      onDrawChange({ type: "FeatureCollection", features: [editFeature] });
      if (mapRef.current && geojson) {
        fitBounds({ map: mapRef.current, geojson, options: { padding: 50 } });
      }
    }
  }, [prevEditFtr, setFeatures, onDrawChange, editFeature]);

  useEffect(() => {
    if (outsideDrawData) {
      const geojson = flattenEditFeature(outsideDrawData) as FeatureCollection<
        Point | LineString | Polygon
      >;
      if (geojson) {
        setFeatures(geojson);
      }
      const combined = geojson
        ? (combineFc(geojson) as FeatureCollection)
        : null;
      onDrawChange(combined);
    }
  }, [outsideDrawData, onDrawChange, setFeatures]);

  // update draw mode
  useEffect(() => {
    if (drawOptions?.mode && prevDrawOpts?.mode !== drawOptions?.mode) {
      // console.log('set mode', drawOptions.mode);
      setMode(drawOptions.mode);
    }
  }, [drawOptions, setMode, prevDrawOpts]);

  useEffect(() => {
    // if features is falsey, clear draw features
    if (drawOptions) {
      const hasFeatures = Object.prototype.hasOwnProperty.call(
        drawOptions,
        "features"
      );
      if (hasFeatures && !drawOptions?.features) {
        setFeatures(emptyFc);
        onDrawChange(null);
      }
    }
  }, [drawOptions, setFeatures, onDrawChange]);

  // ADD OR UPDATE INVENTORY FEATURES
  useEffect(() => {
    if (
      prevInventoryFtrs !== inventoryFeatures &&
      // inventoryFeatures &&
      mapRef.current
    ) {
      addInventorySource(inventoryFeatures);
    }
  }, [inventoryFeatures, prevInventoryFtrs, addInventorySource]);
  // UPDATE LABEL PROPERTY
  useEffect(() => {
    if (mapRef.current) {
      const layoutProp = ["coalesce", ["get", labelProp], ""];
      const lyr = mapRef.current.getLayer("inventoryFtrsSymbols");
      const lyr2 = mapRef.current.getLayer("inventoryFtrsSymbols2");
      const lyr3 = mapRef.current.getLayer("inventoryFtrsSymbols3");
      if (lyr) {
        mapRef.current.setLayoutProperty(
          "inventoryFtrsSymbols",
          "text-field",
          layoutProp
        );
      }
      if (lyr2) {
        mapRef.current.setLayoutProperty(
          "inventoryFtrsSymbols2",
          "text-field",
          layoutProp
        );
      }
      if (lyr3) {
        mapRef.current.setLayoutProperty(
          "inventoryFtrsSymbols3",
          "text-field",
          layoutProp
        );
      }
    }
  }, [labelProp]);
  // UPDATE FEATURES WHEN THEME IS SET/CHANGES
  useEffect(() => {
    if (legendData?.colorProp && legendData?.colorProp !== prevColorProp) {
      setLayerProperties();
      if (mapRef.current && !inventoryFeatures) {
        // reset features to empty array if no data
        const src = mapRef.current.getSource(
          "inventory-features"
        ) as GeoJSONSource;
        const src2 = mapRef.current.getSource(
          "inventory-features-highlight"
        ) as GeoJSONSource;
        if (src) {
          src.setData(emptyFc);
        }
        if (src2) {
          src2.setData(emptyFc);
        }
      }
    }
  }, [
    inventoryFeatures,
    prevColorProp,
    legendData?.colorProp,
    setLayerProperties,
  ]);
  // clear draw feature when draw disabled
  useEffect(() => {
    if (!draw) {
      setFeatures(emptyFc);
    }
  }, [draw, setFeatures]);
  // ADD OR REMOVE DRAW CONTROL
  useEffect(() => {
    if (draw !== prevDraw) {
      if (mapRef.current && draw) {
        addDrawControl();
      } else if (mapRef.current && !draw) {
        onDrawChange(null);
        setMode("simple_select");
        removeDrawControl();
      }
    }
  }, [
    draw,
    prevDraw,
    addDrawControl,
    removeDrawControl,
    onDrawChange,
    setMode,
  ]);

  // ADD OR UPDATE FIELDS
  useEffect(() => {
    if (mapRef.current && fields) {
      addFields(fields);
    } else if (mapRef.current && !fields) {
      const fldsSrc = mapRef.current.getSource("fields") as GeoJSONSource;
      if (fldsSrc) {
        fldsSrc.setData(emptyFc);
      }
    }
  }, [fields, addFields]);

  // handle enterprise layers
  useEffect(() => {
    if (mapRef.current) {
      enterpriseLayers?.forEach((l) => {
        const hasSrc = mapRef.current.getSource(`${l.id}-src`);
        const hasLyr = mapRef.current.getLayer(l.id);
        if (l.visible) {
          if (!hasSrc) {
            if (l.featureCollection) {
              mapRef.current.addSource(`${l.id}-src`, {
                type: "geojson",
                data: l.featureCollection,
              });
            } else {
              // add vector layer
              mapRef.current.addSource(`${l.id}-src`, {
                type: "vector",
                tiles: [`${l.url}?cb=${Math.random()}`],
              });
            }
          }
          if (!hasLyr) {
            if (l.geometryType) {
              mapRef.current.addLayer({
                id: l.id,
                ...(l.url
                  ? {
                      "source-layer": "default",
                    }
                  : {}),
                source: `${l.id}-src`,
                type: l.geometryType.includes("point") ? "circle" : "line",
                paint: getPaint({
                  geomType: l.geometryType,
                  color: l.color,
                  fillAsLine: true,
                }),
              } as CircleLayer | LineLayer);
            }
          } else {
            mapRef.current.setFilter(l.id, null);
          }
        } else if (hasLyr) {
          mapRef.current.setFilter(l.id, ["==", "1", "2"]);
        }
      });
    }
  }, [enterpriseLayers, legendData?.colorProp]);

  // handle imagery layers
  useEffect(() => {
    addRasterLayers();
  }, [addRasterLayers]);

  const helpText = getHelpText();
  return (
    <>
      {draw && helpText ? (
        <div
          className="cai-map-help"
          style={{
            zIndex: 2,
            opacity: 0.85,
            width: "calc(100% - 150px)",
            left: "75px",
          }}
        >
          {helpText}
        </div>
      ) : null}
      <MapView
        mapRef={noop}
        isLoading={isLoading || rastersLoading}
        showFullScreen
        showBasemapSelect={false}
        showHome
        defaultBounds={season?.extent?.bbox}
        fitBoundsOptions={{ padding: 50, animate: false }}
        bounds={season?.extent?.bbox}
        containerStyles={{ flex: 1, flexGrow: 1, height: "100%" }}
        events={{
          load: (e: MapboxEvent) => {
            mapRef.current = e.target;
            const map = mapRef.current;
            const layersCtrl = new LayersControl({
              theme,
              onClick: () => {
                setShowLayersDrawer(!showLayersDrawer);
              },
            });
            map.addControl(legendCtrl, "bottom-right");
            map.addControl(layersCtrl, "top-left");
            addRasterLayers();
            setMap(map);
            if (fields) {
              addFields(fields);
            }
            if (inventoryFeatures) {
              addInventorySource(inventoryFeatures);
            }
            if (draw) {
              addDrawControl();
            }
            if (editFeature) {
              fitBounds({
                map: mapRef.current,
                geojson: featureCollection([editFeature]),
              });
            }
            mR(map);

            // pass features from map click to inventory provider
            // for other views to utilize if needed
            map.on("click", (ev) => {
              const bbox = [
                [ev.point.x - 5, ev.point.y - 5],
                [ev.point.x + 5, ev.point.y + 5],
              ] as [PointLike, PointLike];
              const ftrs = map.queryRenderedFeatures(bbox);
              setFeaturesClicked(ftrs);
            });
          },
          "style.load": () => {
            handleStyleLoad();
          },
          moveend: () => {
            updatePolyLabels();
          },
          sourcedataloading: (e: MapSourceDataEvent) => {
            const layerIds = activeRasterLayers
              ?.filter((al) => al.visible)
              .map((al) => `${al.url}-src`);
            if (layerIds?.includes(e.sourceId)) {
              setRastersLoading(true);
            }
          },
          data: (e: MapSourceDataEvent) => {
            const layerIds = activeRasterLayers
              ?.filter((al) => al.visible)
              .map((al) => `${al.url}-src`);
            if (layerIds?.includes(e.sourceId)) {
              setRastersLoading(false);
            }
          },
          idle: () => {
            setRastersLoading(false);
          },
        }}
      />
      <LayersDrawer
        show={showLayersDrawer}
        onClose={() => {
          setShowLayersDrawer(false);
        }}
        mapRef={mapRef.current}
      />
    </>
  );
}
